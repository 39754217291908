import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { Filter } from '../filter.model';
import { TableAction } from '../table-models/table-action.model';

@Component({
  selector: 'app-dynamic-filter-row',
  templateUrl: './dynamic-filter-row.component.html',
  styleUrls: ['./dynamic-filter-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFilterRowComponent {
  @Input() public filters: Filter[] = [];
  @Input() public actions: TableAction<any>[] = [];
  @Input() public isDataLoading = true;
  @Input() public hasSelectedData: boolean = false;
  @Input() public hasSelectedList: boolean = false;
  @Output() public readonly filtersChange: EventEmitter<Filter[]> = new EventEmitter<Filter[]>();
  @Output() public readonly searchChange: EventEmitter<string> = new EventEmitter<string>();

  filterValueChanged(event: any): void {
    this.filters.find((f) => f.fieldLabel === event.fieldLabel)!.value = event.value;
    if (this.filters[0].modelBinding === 'searchTerm') {
      this.searchChange.emit(this.filters[0].value);
    } else {
      this.filtersChange.emit(this.filters);
    }
  }

  public handleActionClick(tableAction: TableAction<any>): void {
    tableAction.action();
  }
}
