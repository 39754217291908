<div class="px-2 py-2 text-left">
  <div
    class="relative truncate w-48"
    *ngIf="text; else spinner"
    [matTooltip]="text"
    matTooltipPositionAtOrigin="true"
    matTooltipPosition="after">
    {{ text }}
  </div>
  <ng-template #spinner>
    <span *ngIf="!enriched">/</span>
    <span *ngIf="enriched" matTooltip="No data available for this lead">/</span>
  </ng-template>
</div>
