import { Injectable } from '@angular/core';
import {
  CRMServiceProxy,
  CreateCrmInfoDto,
  CreateOrUpdateWebhookDto,
  CrmInfoDto,
  CrmType,
  IUpdateCrmInfoDto,
  UpdateCrmInfoDto,
  WebhookDto,
  WebhooksServiceProxy,
  SlackIntegrationServiceProxy,
  SlackIntegrationDto,
} from '@shared/service-proxies/service-proxies';
import { UserInterfaceService } from '@shared/user-interface/user-interface.service';
import { BehaviorSubject, Observable, ReplaySubject, Subject, combineLatest, of } from 'rxjs';
import {
  finalize,
  map,
  shareReplay,
  switchMap,
  tap,
  distinctUntilChanged,
  debounceTime,
} from 'rxjs/operators';
import { CrmInfoDTOExtended } from '../integrations.component';
import { INTEGRATION_TYPE as INTEGRATION_TYPE } from '@shared/consts/app-routes.consts';
import { WhiteLabelService } from '@app/white-label.service';
import { ConfirmationDialogComponent } from '@shared/components/dialogs/confirmation-dialog.component';
import { MatDialog } from '@angular/material/dialog';

const DEFAULT_SELECTED_ITEM = {
  crmType: null,
  name: null,
  creationTime: null,
  apiKey: null,
  id: null,
};

export type IntegrationType = 'public-api' | 'nativeIntegration' | 'import';

export type SelectedCrmRequestInfoDto = {
  name: string;
  apiKey: string;
  crmType: CrmType;
  creationTime: string;
  id?: number;
};
@Injectable()
export class IntegrationsService {
  public readonly crmTypes = [
    {
      name: 'Pipedrive',
      value: 1,
      imageUrl: 'assets/images/crms/pipedrive.svg',
      description: 'Sync your leads with your Pipedrive CRMs with just a click',
      active: 0,
      type: 'nativeIntegration' as IntegrationType,
      subtype: INTEGRATION_TYPE.PIPEDRIVE,
      buttonLabel: 'Connect your CRM',
      buttonRedirectUrl: undefined,
    },
    {
      name: 'Hubspot',
      value: 0,
      imageUrl: 'assets/images/crms/hubspot.svg',
      description: 'Sync your leads with your Hubspot CRMs with just a click',
      active: 0,
      type: 'nativeIntegration' as IntegrationType,
      subtype: INTEGRATION_TYPE.HUBSPOT,
      buttonLabel: 'Connect your CRM',
      buttonRedirectUrl: undefined,
    },
    {
      name: 'Go High Level',
      value: 3,
      imageUrl: 'assets/images/crms/ghl.svg',
      description: 'Sync your leads with your GHL CRMs with just a click',
      active: 0,
      type: 'nativeIntegration' as IntegrationType,
      subtype: INTEGRATION_TYPE.GO_HIGH_LEVEL,
      buttonLabel: 'Connect your CRM',
      buttonRedirectUrl: undefined,
    },
    {
      name: 'Close',
      value: 4,
      imageUrl: 'assets/images/crms/close.svg',
      description: 'Sync your leads with your Close CRMs with just a click',
      active: 0,
      type: 'nativeIntegration' as IntegrationType,
      subtype: INTEGRATION_TYPE.CLOSE,
      buttonLabel: 'Connect your CRM',
      buttonRedirectUrl: undefined,
    },
    // {name: 'Salesforce', value: 2, imageUrl: 'assets/images/crms/salesforce.png'}
  ];
  public webhooks = [
    {
      name: 'Webhook',
      value: 1,
      imageUrl: 'assets/images/crms/webhooks.svg',
      description: 'Notify external services when an event occurs in your workspace.',
      active: 0,
      type: 'public-api' as IntegrationType,
      subtype: INTEGRATION_TYPE.WEBHOOK,
      buttonLabel: 'View/Create webhooks',
      isVisible: true,
      buttonRedirectUrl: undefined,
    },
    // {name: 'Salesforce', value: 2, imageUrl: 'assets/images/crms/salesforce.png'}
    {
      name: `${this._whiteLabelService.companyName} API`,
      value: 3,
      imageUrl: this._whiteLabelService.companyLogoSidebar,
      description: `Connect ${this._whiteLabelService.companyName} with your outbound stack in seconds.`,
      active: 0,
      type: 'public-api' as IntegrationType,
      subtype: INTEGRATION_TYPE.API,
      buttonLabel: 'Get API key',
      isVisible: true,
      buttonRedirectUrl: undefined,
    },
  ];
  public imports = [
    {
      name: 'Clay',
      value: 0,
      imageUrl: 'assets/images/crms/clay.svg',
      description: 'Get you enriched leads from Clay into HeyReach',
      active: 0,
      type: 'import' as IntegrationType,
      subtype: INTEGRATION_TYPE.CLAY,
      buttonLabel: 'Connect with Clay',
      isVisible: !this._whiteLabelService.notUs,
      buttonRedirectUrl: 'https://help.heyreach.io/en/articles/9910966-integrate-heyreach-and-clay',
    },
    {
      name: 'RB2B',
      value: 0,
      imageUrl: 'assets/images/crms/rb2b.svg',
      description: 'Get leads from RB2B into HeyReach',
      active: 0,
      type: 'import' as IntegrationType,
      subtype: INTEGRATION_TYPE.RB2B,
      buttonLabel: 'Connect with RB2B',
      isVisible: !this._whiteLabelService.notUs,
      buttonRedirectUrl: undefined,
    },
    {
      name: 'Slack',
      value: 4,
      imageUrl: 'assets/images/crms/slack.svg',
      description: 'Get a Slack message when an event occurs in your workspace.',
      active: 0,
      type: 'import' as IntegrationType,
      subtype: INTEGRATION_TYPE.SLACK,
      buttonLabel: 'Connect with Slack',
      isVisible: !this._whiteLabelService.notUs,
      buttonRedirectUrl: undefined,
    },
    {
      name: 'Zapier',
      value: 0,
      imageUrl: 'assets/images/crms/zapier.svg',
      description: 'Send a zap when an event occurs in your workspace.',
      active: 0,
      type: 'import' as IntegrationType,
      subtype: INTEGRATION_TYPE.ZAPIER,
      buttonLabel: 'Integrate with Zapier',
      isVisible: !this._whiteLabelService.notUs,
      buttonRedirectUrl: undefined,
    },
  ];

  private readonly selectedItemSubject$ = new BehaviorSubject<SelectedCrmRequestInfoDto>(
    DEFAULT_SELECTED_ITEM,
  );
  public readonly selectedItem$: Observable<SelectedCrmRequestInfoDto> =
    this.selectedItemSubject$.asObservable();

  private readonly calculatedHeightSubject$ = new ReplaySubject<number>(0);
  public readonly calculatedHeightPx$: Observable<number> =
    this.calculatedHeightSubject$.asObservable();

  private readonly refreshTriggerSubject$ = new BehaviorSubject<void>(undefined);
  public readonly refreshTrigger$ = this.refreshTriggerSubject$.asObservable();

  private isOnLastPage: boolean = false;
  // Will be removed when pagination is implemented, and this will behave like the webhooks implementation
  private shouldReFetchSlackIntegrations: boolean = true;

  public updateWebhooksPageNumber(pageNumber: number): void {
    this.pageNumberSubject$.next(pageNumber);
  }

  public nextPageNumber(): void {
    if (!this.isOnLastPage) {
      const oldPageNumber = this.pageNumberSubject$.getValue();
      this.pageNumberSubject$.next(oldPageNumber + 1);
    }
  }

  private readonly pageNumberSubject$ = new BehaviorSubject<number>(0);
  public readonly pageNumber$ = this.pageNumberSubject$.asObservable();

  private readonly searchStringSubject$ = new BehaviorSubject<string>('');
  public readonly searchString$: Observable<string> = this.searchStringSubject$.asObservable();

  private readonly previousSearchString$: BehaviorSubject<string> = new BehaviorSubject<string>('');

  private readonly isLoadingSubject$ = new Subject<boolean>();
  public readonly isLoading$: Observable<boolean> = this.isLoadingSubject$
    .asObservable()
    .pipe(shareReplay({ bufferSize: 1, refCount: true }));

  private readonly webHooksState$: BehaviorSubject<WebhookDto[]> = new BehaviorSubject([]);

  public readonly webHooks$: Observable<WebhookDto[]> = combineLatest([
    this.pageNumber$,
    this.searchString$.pipe(distinctUntilChanged()),
    this.refreshTrigger$,
  ]).pipe(
    tap(() => this.isLoadingSubject$.next(true)),
    debounceTime(50),
    switchMap(([pageNumber, searchString, _]) => {
      if (searchString !== this.previousSearchString$.value) {
        this.webHooksState$.next([]);
        this.previousSearchString$.next(searchString);
      }
      if (!this.isOnLastPage) {
        return this.webhooksServiceProxy.getPaginatedWebhooks(pageNumber, searchString ?? '').pipe(
          map((webhooks) => {
            const currentWebHooks = this.webHooksState$.getValue();
            const newWebHooks = [...currentWebHooks, ...webhooks.items];
            this.isOnLastPage = newWebHooks.length === webhooks.totalCount;
            this.webHooksState$.next(newWebHooks);
            return newWebHooks;
          }),
        );
      } else {
        return of(this.webHooksState$.getValue());
      }
    }),
    tap(() => {
      this.calculatedHeightSubject$.next(this._uiService.calculateHeight());
    }),
    finalize(() => this.isLoadingSubject$.next(false)),
  );

  private readonly slackIntegrationsState$: BehaviorSubject<SlackIntegrationDto[]> =
    new BehaviorSubject([]);

  public readonly slackIntegrations$: Observable<SlackIntegrationDto[]> = this.refreshTrigger$.pipe(
    tap(() => this.isLoadingSubject$.next(true)),
    switchMap((_) => {
      if (this.shouldReFetchSlackIntegrations) {
        this.shouldReFetchSlackIntegrations = false;
        this.slackIntegrationsState$.next([]);
        return this.slackServiceProxy.getAll(undefined).pipe(
          map((slackIntegrations) => {
            const currentSlackIntegrations = this.slackIntegrationsState$.getValue();
            const newSlackIntegrations = [...currentSlackIntegrations, ...slackIntegrations];
            this.slackIntegrationsState$.next(newSlackIntegrations.reverse());
            return newSlackIntegrations;
          }),
        );
      } else {
        return of(this.slackIntegrationsState$.getValue());
      }
    }),
    tap(() => {
      this.calculatedHeightSubject$.next(this._uiService.calculateHeight());
    }),
    finalize(() => this.isLoadingSubject$.next(false)),
  );

  private refreshIntegrationsCountSubject = new BehaviorSubject<void>(undefined);

  public readonly integrationsCount$: Observable<any> = this.refreshIntegrationsCountSubject
    .asObservable()
    .pipe(
      switchMap(() => this._integrationsService.getIntegrationsCount()),
      tap((result) => {
        if (result) {
          this.crmTypes[0].active = result.pIPEDRIVE;
          this.crmTypes[1].active = result.hUBSPOT;
          this.crmTypes[2].active = result.hIGHLEVEL;
          this.crmTypes[3].active = result.cLOSE;
          this.webhooks[0].active = result.wEBHOOKS;
          this.imports[2].active = result.sLACK;
        }
      }),
    );

  refreshIntegrationsCount() {
    this.refreshIntegrationsCountSubject.next();
  }

  public readonly crmIntegrations$: Observable<CrmInfoDTOExtended[]> = this.refreshTrigger$.pipe(
    tap(() => this.isLoadingSubject$.next(true)),
    switchMap(() => this._integrationsService.getAll(undefined, undefined)),
    map((crms) =>
      crms.map((crm: CrmInfoDto) => {
        const findType = this.crmTypes.find((x) => x.value === crm.crmType);
        return {
          ...crm,
          crmTypeImg: findType?.imageUrl,
          crmTypeName: findType?.name,
          subtype: findType?.subtype,
        } as CrmInfoDTOExtended;
      }),
    ),
    tap(() => {
      this.calculatedHeightSubject$.next(this._uiService.calculateHeight());
    }),
    finalize(() => this.isLoadingSubject$.next(false)),
  );

  private testStatusSubject$ = new BehaviorSubject<'initial' | 'loading' | 'success' | 'failed'>(
    'initial',
  );

  public readonly testStatus$ = this.testStatusSubject$.asObservable();
  private testWebhookResponseSubject$ = new BehaviorSubject<any>(null);
  public readonly testWebhookResponse$ = this.testWebhookResponseSubject$.asObservable();

  constructor(
    private _integrationsService: CRMServiceProxy,
    private webhooksServiceProxy: WebhooksServiceProxy,
    private slackServiceProxy: SlackIntegrationServiceProxy,
    private _uiService: UserInterfaceService,
    private _whiteLabelService: WhiteLabelService,
    private dialog: MatDialog,
  ) {}

  searchByMethod(searchTerm: string | null): void {
    this.isOnLastPage = false;
    this.webHooksState$.next([]);
    this.searchStringSubject$.next(searchTerm ?? '');
    this.updateWebhooksPageNumber(0);
  }

  updateSelectedItem(selectedItem: Partial<SelectedCrmRequestInfoDto>) {
    const oldValue = this.selectedItemSubject$.getValue();
    const newValue = {
      ...oldValue,
      ...selectedItem,
    };
    this.selectedItemSubject$.next(newValue);
  }

  createCrm(createRequest: SelectedCrmRequestInfoDto) {
    this.isLoadingSubject$.next(true);
    this._integrationsService
      .createCrmInfo({
        crmType: createRequest.crmType,
        name: createRequest.name,
        apiKey: createRequest.apiKey,
      } as CreateCrmInfoDto)
      .pipe(
        finalize(() => {
          this.isLoadingSubject$.next(false);
          this.updateSelectedItem(DEFAULT_SELECTED_ITEM);
        }),
      )
      .subscribe((crmDto: CrmInfoDto) => {
        this.refreshTriggerSubject$.next();
      });
  }

  createOrUpdateWebhook(createRequest: CreateOrUpdateWebhookDto) {
    this.isLoadingSubject$.next(true);
    this.webhooksServiceProxy
      .createOrUpdateWebhook(createRequest)
      .pipe(
        finalize(() => {
          this.isLoadingSubject$.next(false);
          this.updateSelectedItem(DEFAULT_SELECTED_ITEM);
          this.refreshIntegrationsCount();
        }),
      )
      .subscribe((webhookDto) => {
        const currentWebHooks = this.webHooksState$.getValue();
        const indexOfEditedWebhook = currentWebHooks.findIndex(
          (webHook) => webHook.id === webhookDto.id,
        );
        if (indexOfEditedWebhook > -1) {
          currentWebHooks[indexOfEditedWebhook] = webhookDto;
        } else {
          currentWebHooks.unshift(webhookDto);
        }
        this.webHooksState$.next(currentWebHooks);
      });
  }

  updateCrmDto(createRequest: SelectedCrmRequestInfoDto) {
    this.isLoadingSubject$.next(true);
    const body: UpdateCrmInfoDto = new UpdateCrmInfoDto({
      crmType: createRequest.crmType,
      name: createRequest.name,
      apiKey: createRequest.apiKey,
      id: createRequest.id,
    } as IUpdateCrmInfoDto);

    this._integrationsService
      .updateCrmInfo(body)
      .pipe(
        finalize(() => {
          this.isLoadingSubject$.next(false);
          this.updateSelectedItem(DEFAULT_SELECTED_ITEM);
          this.refreshIntegrationsCount();
        }),
      )
      .subscribe(() => {
        this.refreshTriggerSubject$.next();
      });
  }

  deleteCrm(crmId: number): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Delete CRM',
        description: 'Are you sure you want to delete this CRM integration?',
        confirmButtonColor: 'red',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        abp.ui.setBusy();
        this._integrationsService
          .deleteCrmInfo(crmId)
          .pipe(
            finalize(() => {
              abp.ui.clearBusy();
              this.isLoadingSubject$.next(false);
              this.refreshIntegrationsCount();
            }),
          )
          .subscribe(() => {
            this.refreshTriggerSubject$.next();
          });
      }
    });
  }

  deleteWebhook(webhookId: number): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Delete Webhook',
        description: 'Are you sure you want to delete this Webhook?',
        confirmButtonColor: 'red',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        abp.ui.setBusy();
        this.webhooksServiceProxy
          .delete(webhookId)
          .pipe(
            finalize(() => {
              const deleteWebhooks = this.webHooksState$.value.filter(
                (webhook) => webhook.id !== webhookId,
              );
              this.webHooksState$.next(deleteWebhooks);
              abp.ui.clearBusy();
              this.refreshIntegrationsCount();
            }),
          )
          .subscribe(() => {
            this.refreshTriggerSubject$.next();
          });
      }
    });
  }

  public testWebhook(webhookId: number): void {
    this.testStatusSubject$.next('loading');
    this.webhooksServiceProxy.testWebhook(webhookId).subscribe(
      (response) => {
        this.testStatusSubject$.next('success');
        this.testWebhookResponseSubject$.next(response);
      },
      (error) => {
        const result = error.error?.result || error.error;
        this.testStatusSubject$.next('failed');
        this.testWebhookResponseSubject$.next(result);
      },
    );
  }

  createOrUpdateSlackIntegration(createRequest: SlackIntegrationDto) {
    this.isLoadingSubject$.next(true);
    if (createRequest.id) {
      this.slackServiceProxy
        .update(createRequest)
        .pipe(
          finalize(() => {
            this.isLoadingSubject$.next(false);
            this.updateSelectedItem(DEFAULT_SELECTED_ITEM);
            this.refreshIntegrationsCount();
          }),
        )
        .subscribe((integration) => {
          this.handleEditedSlackIntegration(integration);
        });
    } else {
      this.slackServiceProxy
        .createSlackIntegration(createRequest)
        .pipe(
          finalize(() => {
            this.isLoadingSubject$.next(false);
            this.updateSelectedItem(DEFAULT_SELECTED_ITEM);
            this.refreshIntegrationsCount();
          }),
        )
        .subscribe((integration) => {
          this.handleEditedSlackIntegration(integration);
        });
    }
  }

  private handleEditedSlackIntegration(integration: SlackIntegrationDto) {
    const currentSlackIntegrations = this.slackIntegrationsState$.getValue();
    const indexOfEditedIntegration = currentSlackIntegrations.findIndex(
      (i) => i.id === integration.id,
    );
    if (indexOfEditedIntegration > -1) {
      currentSlackIntegrations[indexOfEditedIntegration] = integration;
    } else {
      currentSlackIntegrations.unshift(integration);
    }
    this.slackIntegrationsState$.next(currentSlackIntegrations);
    this.refreshTriggerSubject$.next();
  }

  deleteSlackIntegration(integrationId: number): void {
    const dialogRef = this.dialog.open(ConfirmationDialogComponent, {
      data: {
        message: 'Delete Slack Integration',
        description: 'Are you sure you want to delete this Slack Integration?',
        confirmButtonColor: 'red',
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        abp.ui.setBusy();
        this.slackServiceProxy.delete(integrationId).subscribe(() => {
          const remainingIntegrations = this.slackIntegrationsState$.value.filter(
            (integration) => integration.id !== integrationId,
          );
          this.slackIntegrationsState$.next(remainingIntegrations);
          this.refreshTriggerSubject$.next();
          abp.ui.clearBusy();
          this.refreshIntegrationsCount();
        });
      }
    });
  }

  public testSlackIntegration(slackIntegrationId: number): void {
    this.testStatusSubject$.next('loading');
    this.slackServiceProxy.testIntegration(slackIntegrationId).subscribe(
      (response) => {
        this.testStatusSubject$.next(response === 200 ? 'success' : 'failed');
        this.testWebhookResponseSubject$.next(response);
      },
      (error) => {
        const result = error.error?.result || error.error;
        this.testStatusSubject$.next('failed');
        this.testWebhookResponseSubject$.next(result);
      },
    );
  }
}
