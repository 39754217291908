import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { enableProdMode } from '@angular/core';
import { environment } from './environments/environment';
import { RootModule } from './root.module';
import { hmrBootstrap } from './hmr';
import * as Sentry from '@sentry/angular-ivy';
import Gleap from 'gleap';
import 'moment/min/locales.min';
import 'moment-timezone';

declare var window: any;

if (environment.production) {
  Sentry.init({
    dsn: 'https://daf0a58a96f8bc785d55752827314ebf@o4506586738917376.ingest.sentry.io/4506671602532352',
    integrations: [
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          'localhost',
          /^https:\/\/app.heyreach\.io/,
          /^http:\/\/api.heyreach\.io/,
          /^https:\/\/app-dev.kanterstrajk\.xyz/,
          /^http:\/\/api-dev.kanterstrajk\.xyz/,
        ],
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    tracesSampleRate: 0.2,
    debug: false,
    environment: environment.name,
    attachStacktrace: true,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });

  enableProdMode();
}

if (
  ['app.heyreach.io', 'app-dev.kanterstrajk.xyz', 'app-staging.kanterstrajk.xyz'].includes(
    window.location.hostname,
  )
) {
  // Gleap.setLanguage('en');
  // Gleap.initialize('LbWKJbIvPBYFxuMw54InwlQdieZDohgF');
}

const bootstrap = () => {
  return platformBrowserDynamic().bootstrapModule(RootModule);
};

/* "Hot Module Replacement" is enabled as described on
 * https://medium.com/@beeman/tutorial-enable-hrm-in-angular-cli-apps-1b0d13b80130#.sa87zkloh
 */

if (environment.hmr) {
  if (module['hot']) {
    hmrBootstrap(module, bootstrap); // HMR enabled bootstrap
  } else {
    console.error('HMR is not enabled for webpack-dev-server!');
    console.log('Are you using the --hmr flag for ng serve?');
  }
} else {
  bootstrap(); // Regular bootstrap
}
