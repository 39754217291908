export class AppRoutes {
  static readonly updatePassword = 'update-password';
  static readonly invitations = 'invitations';
  static readonly billing = 'billing';
  static readonly integrations = 'integrations';
  static readonly linkedAccounts = 'linkedin-accounts';
}

export const enum INTEGRATION_TYPE {
  ZAPIER = 'zapier',
  WEBHOOK = 'webhooks',
  PIPEDRIVE = 'pipedrive',
  HUBSPOT = 'hubspot',
  GO_HIGH_LEVEL = 'highlevel',
  CLOSE = 'close',
  API = 'api',
  SLACK = 'slack',
  RB2B = 'rb2b',
  CLAY = 'clay',
}
