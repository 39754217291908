import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, ReplaySubject, Subject } from 'rxjs';
import { TableColumn } from '../table-models/table-column.model';
import { LocalStorageService } from './local-storage.service';
import { Filter } from '../filter.model';

@Injectable()
export class DataTableService implements OnDestroy {
  public readonly filters$: Observable<Filter[]>;
  public readonly internalRefreshTrigger$: Observable<void>;
  public readonly refreshTrigger$: Observable<boolean>;

  private readonly filters$$: BehaviorSubject<Filter[]>;
  private readonly internalRefreshTrigger$$ = new Subject<void>();
  private readonly refreshTrigger$$ = new ReplaySubject<boolean>(1);
  private readonly destroy$$ = new Subject<void>();

  private readonly tableColumnsVisibilitiesStorageKey = `heyreach$_TableColumnsVisibilities`;
  private readonly filtersStorageKey = `heyreach_Filters`;

  public set filters(filterValues: Filter[]) {
    this.filters$$.next(filterValues);
    this.setFiltersToStorage(filterValues);
  }

  constructor(private readonly localStorageService: LocalStorageService) {
    this.filters$$ = new BehaviorSubject<Filter[]>(this.getFiltersFromStorage() ?? []);
    this.filters$ = this.filters$$.asObservable();
    this.internalRefreshTrigger$ = this.internalRefreshTrigger$$.asObservable();
    this.refreshTrigger$ = this.refreshTrigger$$.asObservable();
    this.refreshTrigger$$.next(false);
  }

  public ngOnDestroy(): void {
    this.destroy$$.next();
  }

  public triggerRefresh(): void {
    this.refreshTrigger$$.next(false);
  }

  public getTableColumnsVisibilitiesFromStorage(
    tableColumnsKey: string = 'defaultKey',
  ): TableColumn[] | null {
    return this.localStorageService.getItemSync<TableColumn[]>(
      `${this.tableColumnsVisibilitiesStorageKey}_${tableColumnsKey}`,
    );
  }

  public setTableColumnsVisibilitiesToStorage(
    tableColumnVisibilities: TableColumn[],
    tableColumnsKey: string = 'defaultKey',
  ): TableColumn[] {
    return this.localStorageService.setItemSync<TableColumn[]>(
      `${this.tableColumnsVisibilitiesStorageKey}_${tableColumnsKey}`,
      tableColumnVisibilities,
    );
  }

  public clearFilters(): void {
    this.filters = [];
  }

  public removeFilter(filterValueToRemove: Filter): void {
    this.filters = this.filters$$.value.filter(
      (filterValue) => filterValue !== filterValueToRemove,
    );
  }

  private setFiltersToStorage(filters: Filter[]): Filter[] {
    return this.localStorageService.setItemSync<Filter[]>(this.filtersStorageKey, filters);
  }

  private getFiltersFromStorage(): Filter[] | null {
    return this.localStorageService.getItemSync<Filter[]>(this.filtersStorageKey);
  }
}
