<ng-container *ngIf="filters">
  <ng-container *ngFor="let filter of filters">
    <div
      class="flex flex-col sm:flex-row border-secondary-muted text-secondary items-center max-w-xs sm:max-w-none bg-white rounded-t-lg pl-7 pr-1">
      <app-dynamic-field
        [filter]="filter"
        (filterChange)="filterValueChanged($event)"></app-dynamic-field>
    </div>
  </ng-container>
  <button
    heyreachButton
    buttonType="custom-border"
    *ngFor="let action of actions"
    [disabled]="isDataLoading"
    [attr.title]="action.title"
    (click)="handleActionClick(action)"
    [class.hidden]="action.buttonShouldHide && !hasSelectedData"
    [class.hidden]="action.showButton && hasSelectedList"
    [ngClass]="action.buttonClasses">
    <div class="flex items-center">
      <mat-icon class="icon-size-4 mr-1" [svgIcon]="action.icon"></mat-icon>
      <span class="ml-2 font-medium text-sm">{{ action.title }}</span>
    </div>
  </button>
</ng-container>
