import {
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subject } from 'rxjs';

import { TableColumn, TableColumnVisibility } from './table-models/table-column.model';
import { TableAction } from './table-models/table-action.model';
import { PageValue, Pagination } from './table-models/pagination.model';
import { MessageBoxType } from './table-models/message-box-type.model';
import { SortBy } from './table-models/sort-types.model';
import { genericSortItems } from './sort-functions/sort-items';
import { DataTableService } from './services/data-table.service';
import { takeUntil, tap } from 'rxjs/operators';
import { Filter } from './filter.model';

export interface BaseFilterRequest {
  [key: string]:
    | number
    | string
    | null
    | undefined
    | boolean
    | Date
    | BaseFilterRequest
    | BaseFilterRequest[]
    | number[]
    | string[]
    | boolean[]
    | Date[]
    | undefined[]
    | null[]
    | any[]
    | any;
}

type TypeWithStringKeys = {
  [key: string]: any;
};

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.scss'],
  // changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [DataTableService],
})
export class DataTableComponentExtended<T extends TypeWithStringKeys>
  implements OnInit, OnChanges, OnDestroy, AfterContentInit
{
  @Input() public data: T[] = [];
  @Input() public listCount: number = 0;
  @Input() public error?: string | null;
  @Input() public availableTableColumns: TableColumn[] = [];
  @Input() public actions: TableAction<T>[] = [];
  @Input() public activeRow?: T;
  @Input() public isDataLoading = false;
  @Input() public selectEnabled = true;
  @Input() public pagination!: Pagination;
  @Input() public searchEnabled = true;
  @Input() public filterEnabled = true;
  @Input() public columnPickerEnabled = true;
  @Input() public refreshEnabled = true;
  @Input() public searchByFields: string[] = [];
  @Input() public filters: Filter[] = [];
  @Input() public showIcon: boolean = false;
  @Input() public loadedLastPage: boolean = false;
  @Input() public showButton: boolean = false;
  @Input() public tableColumnsKey: string = 'defaultKey';
  @Input() public notDataFoundImgLink: string = '';
  @Input() public showNoData: boolean = true;

  @Input() public noDataMessage: {
    type: MessageBoxType;
    message: string;
  } = {
    type: 'warning',
    message: 'No data available. Please check your filter or try to (auto)refresh.',
  };

  @Output() public readonly refresh = new EventEmitter<boolean>();
  @Output() public readonly rowClicked: EventEmitter<T> = new EventEmitter<T>();
  @Output() public readonly sortBy: EventEmitter<SortBy> = new EventEmitter<SortBy>();
  @Output() public readonly searchBy: EventEmitter<string | null> = new EventEmitter<
    string | null
  >();
  @Output() public readonly filterValueChanged: EventEmitter<BaseFilterRequest> =
    new EventEmitter<BaseFilterRequest>();
  @Output() public readonly pageSelected: EventEmitter<PageValue> = new EventEmitter<PageValue>();
  @Output() public readonly selectionChanged: EventEmitter<BaseFilterRequest[]> = new EventEmitter<
    BaseFilterRequest[]
  >();
  @Output() searchInputChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() allSelectedChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  public readonly filters$ = this.dataTableService.filters$;

  public activeHeadingSortIndex!: number;
  public manualRefreshActive = false;
  public tableColumns!: TableColumn[];
  public columnFiltersEnabled = false;
  public availableData!: T[];
  public meaningfulPagination = false;
  public selectedData: T[] = [];
  public filtersShown = false;
  public searchTerm: string = '';

  @ViewChild('selectAll') private readonly selectAll!: ElementRef<HTMLInputElement>;
  @ViewChild('tableWrapper') private readonly tableWrapper!: ElementRef<HTMLDivElement>;
  //filter component
  @ViewChild('filter') private readonly filter!: any;

  private readonly destroy$$ = new Subject<void>();

  @Input() public searchFunction: (
    searchTerm: string | null,
    dataSet: T[],
    searchByFields: string[],
  ) => T[] = (searchTerm: string | null, dataSet: T[], searchByFields: string[]) =>
    this.defaultSearchFunction(searchTerm, dataSet, searchByFields);

  @Input() public compareFunction: (itemOne: T, itemTwo: T) => boolean = (itemOne, itemTwo) =>
    itemOne === itemTwo;
  @Input() public trackByFunction: (index: number, item: T) => string | number = (
    index: number,
    _: T,
  ) => index;

  // THis can be extracted into a completely independent functions and used from there
  private readonly defaultSearchFunction = (
    searchTerm: string | null,
    dataSet: T[],
    searchByFields: string[],
  ): T[] => {
    const data: T[] = [];
    if (searchTerm) {
      const regExp = new RegExp(searchTerm, 'i');

      dataSet.forEach((dataItem: T) => {
        for (const field of searchByFields) {
          if (dataItem[field] && regExp.test(dataItem[field])) {
            data.push(dataItem);
            break;
          }
        }
      });
    } else {
      data.push(...dataSet);
    }
    return data;
  };

  constructor(private readonly dataTableService: DataTableService) {}

  public ngAfterContentInit(): void {
    this.checkAndHandleSelected();
  }

  public ngOnInit(): void {
    this.dataTableService.refreshTrigger$
      .pipe(
        tap(() => {
          this.manualRefreshActive = true;
        }),
        takeUntil(this.destroy$$),
      )
      .subscribe((refreshTrigger) => {
        this.refresh.emit(refreshTrigger);
      });
  }

  public ngOnChanges(simpleChanges: SimpleChanges): void {
    if (simpleChanges['availableTableColumns']) {
      this.setTableColumns(
        this.dataTableService.getTableColumnsVisibilitiesFromStorage(this.tableColumnsKey),
      );
    }
    if (simpleChanges['data']) {
      this.availableData = [...(this.data ?? [])];
      // only keep selectedItems that are in the new availableData and if heading for all items select is checked select all;
      if (this.selectAll?.nativeElement?.checked) {
        this.selectedData = [...this.availableData];
        this.selectionChanged.emit(this.selectedData);
      } else {
        this.selectedData = [
          ...this.selectedData.filter((selectedDataItem) =>
            this.availableData.some((availableDataItem) =>
              this.compareFunction(availableDataItem, selectedDataItem),
            ),
          ),
        ];
      }

      this.checkAndHandleSelected();
    }
    if (simpleChanges['pagination']) {
      // this.meaningfulPagination = isMeaningfulPagination(this.pagination);
    }
  }

  public ngOnDestroy(): void {
    this.destroy$$.next();
  }

  public handleClearFilters(): void {
    this.dataTableService.clearFilters();
  }

  public handleFilterValuesChange(filterValues: Filter[]): void {
    const request = filterValues.reduce((acc, filter: Filter) => {
      acc[filter.fieldLabel] = filter.value;
      return acc;
    }, {});
    this.filterValueChanged.emit(request);
  }

  public handleSelectChange($event: Event, dataItem?: T): void {
    if (dataItem) {
      const selectedDataItemIndex = this.selectedData.findIndex((selectedDataItem) =>
        this.compareFunction(selectedDataItem, dataItem),
      );
      if (selectedDataItemIndex === -1) {
        this.selectedData = [...this.selectedData, dataItem];
      } else {
        this.selectedData.splice(selectedDataItemIndex, 1);
        this.selectedData = [...this.selectedData];
      }
    } else {
      const checkboxTarget = $event.target as HTMLInputElement;
      checkboxTarget.indeterminate = false;
      if (checkboxTarget.checked) {
        this.selectedData = [...(this.availableData ?? [])];
      } else {
        this.selectedData = [];
      }
    }
    this.selectionChanged.emit(this.selectedData);
    this.allSelectedChanged.emit(($event.target as HTMLInputElement).checked);
    this.checkAndHandleSelected();
  }

  public handleActionClick(tableAction: TableAction<T>): void {
    if (!tableAction.confirmText) {
      tableAction.action(this.selectedData);
      return;
    }

    //TODO if we have some confirmation dialog should be implemented here
  }

  public searchByMethod(searchTerm: string | null): void {
    this.searchBy.emit(searchTerm);
    // DEV NOTE: If pagination will be implemented later
    // this.availableData = this.searchFunction(searchTerm, this.data, this.searchByFields);
  }

  public handleTableColumnsChange(tableColumns: TableColumn[]): void {
    this.setTableColumns(tableColumns);
    this.dataTableService.setTableColumnsVisibilitiesToStorage(tableColumns, this.tableColumnsKey);
  }

  public trackByIndex(index: number): number {
    return index;
  }

  public toTableColumn(tableColumn: any): TableColumn {
    return tableColumn as TableColumn;
  }

  public sort($event: SortBy): void {
    this.activeHeadingSortIndex = $event.activeIndex;
    if (this.pagination) {
      this.sortBy.emit($event);
    } else {
      // Example of independent function that can be extracted and used from there
      genericSortItems($event, this.availableData);
      this.availableData = [...this.availableData];
    }
  }

  public handleFilterButtonClick(): void {
    if (!this.filtersShown) {
      this.filtersShown = true;
    } else {
      this.filter.handleCloseFilter();
    }
  }

  public handleManualRefreshClick(): void {
    console.log('trigger refresh');
  }

  private setTableColumns(tableColumnVisibilities?: TableColumn[]): void {
    if (tableColumnVisibilities && tableColumnVisibilities.length > 0) {
      const _tableColumn = tableColumnVisibilities
        .filter((i) => i && i.id)
        .map((i) => ({
          ...i,
          templateRef: this.pullColumnTemplate(i, this.availableTableColumns)?.templateRef,
        }));

      this.tableColumns = [...(_tableColumn ?? this.availableTableColumns)];
    } else {
      this.tableColumns = [...this.availableTableColumns];
    }
    this.columnFiltersEnabled = this.tableColumns
      .filter((tableColumn: TableColumn) => !tableColumn.hidden)
      .some((tableColumn: TableColumn) => tableColumn.filterTemplateRef);
  }

  private pullColumnTemplate = (column: TableColumn, availableTableColumns: TableColumn[]) =>
    availableTableColumns.find((c) => c.id === column.id);

  private checkAndHandleSelected(): void {
    if (!this.selectAll?.nativeElement) {
      return;
    }
    const selectAllCheckbox = this.selectAll.nativeElement;
    if (this.selectedData.length !== 0 && this.selectedData.length === this.availableData?.length) {
      selectAllCheckbox.checked = true;
      selectAllCheckbox.indeterminate = false;
    } else if (this.selectedData.length === 0) {
      selectAllCheckbox.checked = false;
      selectAllCheckbox.indeterminate = false;
    } else {
      selectAllCheckbox.indeterminate = true;
    }
  }
}
